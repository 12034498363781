import React, {useEffect} from 'react'
import DatePicker from 'react-datepicker'
import {formatISO, parseISO} from 'date-fns'
import {
  IbeConfig,
  SearchGuestParams,
  SearchParams,
  SearchSpecialRatesParams
} from '../../types'
import SearchRoomsGuestsControl from './SearchRoomsGuestsControl'
import clsx from 'clsx'
import SearchSpecialRatesControl from './SearchSpecialRatesControl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faCalendar,
  faChevronDown,
  faLocationDot,
  faSearch,
  faTicket,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga4'
import {getLogger} from '../../services/logging'

const logger = getLogger('SearchBar')

export interface SearchBarProps {
  config: IbeConfig
  searchParams: SearchParams
  showProperty: boolean
  onSubmit: (searchParams: SearchParams) => void
  className?: string
}

const SearchBar = ({
  config,
  searchParams,
  showProperty,
  onSubmit,
  className = ''
}: SearchBarProps) => {
  const [searchForm, setSearchForm] = React.useState<SearchParams>({
    ...searchParams,
    propertyId: showProperty ? searchParams.propertyId : undefined
  })
  const {locations, properties} = config

  const locationProperties =
    searchForm.location !== 'ALL'
      ? properties.filter(property => property.location === searchForm.location)
      : []

  const startDate = searchForm?.dateRange?.start
    ? parseISO(searchForm.dateRange.start)
    : null
  const endDate = searchForm?.dateRange?.end
    ? parseISO(searchForm.dateRange.end)
    : null

  console.log('searchForm: ', searchForm)

  useEffect(() => {
    setSearchForm({
      ...searchParams,
      propertyId: showProperty ? searchParams.propertyId : undefined
    })
  }, [searchParams, showProperty])

  return (
    <form
      onSubmit={e => {
        ReactGA.event({
          category: 'IBE',
          action: 'Search_Submit',
          value: 1
        })
        logger.info({message: 'Search form submitted', data: {searchForm}})
        e.preventDefault()
        onSubmit(searchForm)
      }}
      className={clsx(
        'bg-white border shadow-md',
        'flex flex-col gap-4 py-6 rounded-2xl',
        'lg:flex-row lg:items-center lg:space-between lg:p-4 lg:rounded-full',
        className
      )}
    >
      <div
        className={clsx(
          'flex-1 px-6',
          'flex flex-col space-between',
          'lg:flex-row lg:items-center'
        )}
      >
        {/* Location Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon icon={faLocationDot} className="text-cabana" />
            <span className="text-light-midnight">Location</span>
          </div>
          <div className="flex flex-row items-center">
            <select
              style={{
                appearance: 'none'
              }}
              className={clsx(
                'text-sm font-medium cursor-pointer',
                'w-full py-3.5',
                'lg:py-1 lg:w-32',
                'border-0 focus:border-0 focus:ring-0 outline-none'
              )}
              value={searchForm.location}
              onChange={e => {
                logger.info({
                  message: 'Location selected',
                  data: {location: e.target.value}
                })
                setSearchForm({
                  ...searchForm,
                  location: e.target.value,
                  propertyId: undefined
                })
              }}
            >
              <option key="ALL" value="ALL">
                All Locations
              </option>
              {locations.map(location => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faChevronDown} className="flex-0 text-2xs" />
          </div>
        </div>

        <div className="divider lg:divider-horizontal mt-0.5 mb-2"></div>

        {/* Property Control */}
        {showProperty && (
          <>
            <div className={clsx('form-control')}>
              <div
                className={clsx(
                  'text-xs font-medium',
                  'flex flex-row items-center gap-2'
                )}
              >
                <FontAwesomeIcon icon={faBuilding} className="text-cabana" />
                <span className="text-light-midnight">Property</span>
              </div>
              <div className="flex flex-row items-center">
                <select
                  style={{
                    appearance: 'none'
                  }}
                  className={clsx(
                    'text-sm font-medium cursor-pointer',
                    'w-full py-3.5',
                    'lg:py-1 lg:w-32',
                    'border-0 focus:border-0 focus:ring-0 outline-none'
                  )}
                  value={searchForm.propertyId}
                  onChange={e => {
                    logger.info({
                      message: 'Property selected',
                      data: {location: e.target.value}
                    })
                    setSearchForm({
                      ...searchForm,
                      propertyId: e.target.value
                    })
                  }}
                >
                  <option key="ALL" value="ALL">
                    All Properties
                  </option>
                  {locationProperties.map(property => (
                    <option
                      key={property.propertyApaleoId}
                      value={property.propertyApaleoId}
                    >
                      {property.name}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="flex-0 text-2xs"
                />
              </div>
            </div>

            <div className="divider lg:divider-horizontal mt-0.5 mb-2"></div>
          </>
        )}

        {/* Date Range Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon icon={faCalendar} className="text-cabana" />
            <span className="text-light-midnight">Dates</span>
          </div>
          <DatePicker
            className={clsx(
              'font-medium text-sm',
              'bg-transparent w-full lg:w-48 py-3.5',
              'lg:py-1'
            )}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MMM d, yyyy"
            minDate={new Date()}
            onChange={([start, end]: [Date | null, Date | null]) => {
              logger.info({message: 'Date range updated', data: {start, end}})
              setSearchForm({
                ...searchForm,
                dateRange: {
                  type: 'Specific',
                  start: start ? formatISO(start) : undefined,
                  end: end ? formatISO(end) : undefined
                }
              })
            }}
          />
        </div>

        <div className="divider lg:divider-horizontal mt-0.5 mb-2"></div>

        {/* Rooms and Guests Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon icon={faUser} className="text-cabana" />
            <span className="text-light-midnight">Rooms & Guests</span>
          </div>
          <SearchRoomsGuestsControl
            searchGuestParams={searchForm.guests}
            onChange={(searchGuestsParams: SearchGuestParams) => {
              logger.info({
                message: 'Rooms and guests updated',
                data: {searchGuestsParams}
              })
              setSearchForm({
                ...searchForm,
                guests: searchGuestsParams
              })
            }}
          />
        </div>

        <div className="divider lg:divider-horizontal mt-0.5 mb-2"></div>

        {/* Special Rates Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon icon={faTicket} className="text-cabana" />
            <span className="text-light-midnight">Special Rate</span>
          </div>
          <SearchSpecialRatesControl
            specialRates={searchForm.specialRates}
            onChange={(specialRates: SearchSpecialRatesParams) => {
              logger.info({
                message: 'Special rates updated',
                data: {specialRates}
              })
              setSearchForm({
                ...searchForm,
                specialRates
              })
            }}
          />
        </div>
      </div>

      {/* Search Button */}
      <div className={clsx('flex-0', 'lg:mr-2')}>
        <button
          type="submit"
          className={clsx(
            'btn btn-secondary btn-wide rounded-full',
            'flex items-center justify-center',
            'mx-auto',
            'lg:btn-circle lg:mt-0 lg:mx-0'
          )}
        >
          <FontAwesomeIcon icon={faSearch} className="text-lg" />
          <span className="block lg:hidden">Search</span>
        </button>
      </div>
    </form>
  )
}

export default SearchBar
