// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../../node_modules/react-datepicker/dist/react-datepicker.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* date picker customizations */
.react-datepicker__input-container, .react-datepicker-wrapper {
  /* height: 18.5px;
  line-height: 18.5px; */
  cursor: pointer;
}

.react-datepicker__input-container > input{
  border-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.react-datepicker__input-container > input:focus{
  border-width: 0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-datepicker__input-container > input {
  /* height: 18.5px;
  line-height: 18.5px; */
  cursor: pointer;
}

.react-datepicker_header{
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1,oklch(var(--b1)/var(--tw-bg-opacity)));
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker__day:hover{
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p,oklch(var(--p)/var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected{
  --tw-bg-opacity: 1;
  background-color: var(--fallback-s,oklch(var(--s)/var(--tw-bg-opacity)));
  font-weight: 600;
  --tw-text-opacity: 1;
  color: var(--fallback-p,oklch(var(--p)/var(--tw-text-opacity)));
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--in-range){
  --tw-bg-opacity: 1;
  background-color: var(--fallback-s,oklch(var(--s)/var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-p,oklch(var(--p)/var(--tw-text-opacity)));
}
`, "",{"version":3,"sources":["webpack://./src/styles/datepicker.css"],"names":[],"mappings":"AAEA,+BAA+B;AAC/B;EACE;wBACsB;EACtB,eAAe;AACjB;;AAGE;EAAA,iBAAwD;EAAxD,8BAAwD;EAAxD;AAAwD;;AAAxD;EAAA,iBAAwD;EAAxD,2GAAwD;EAAxD,yGAAwD;EAAxD;AAAwD;;AAD1D;EAEE;wBACsB;EACtB,eAAe;AACjB;;AAGE;EAAA,kBAAkB;EAAlB;AAAkB;;AAGpB;EACE,aAAa;AACf;;AAGE;EAAA,kBAA4B;EAA5B,wEAA4B;EAA5B,oBAA4B;EAA5B;AAA4B;;AAO5B;;;;EAAA,kBAA8C;EAA9C,wEAA8C;EAA9C,gBAA8C;EAA9C,oBAA8C;EAA9C;AAA8C;;AAI9C;EAAA,kBAAgC;EAAhC,wEAAgC;EAAhC,oBAAgC;EAAhC;AAAgC","sourcesContent":["@import 'react-datepicker/dist/react-datepicker.css';\n\n/* date picker customizations */\n.react-datepicker__input-container, .react-datepicker-wrapper {\n  /* height: 18.5px;\n  line-height: 18.5px; */\n  cursor: pointer;\n}\n\n.react-datepicker__input-container > input {\n  @apply border-0 focus:border-0 focus:ring-0 outline-none;\n  /* height: 18.5px;\n  line-height: 18.5px; */\n  cursor: pointer;\n}\n\n.react-datepicker_header {\n  @apply bg-base-100;\n}\n\n.react-datepicker-popper {\n  z-index: 9999;\n}\n\n.react-datepicker__day:hover {\n  @apply bg-primary text-white;\n}\n\n.react-datepicker__day--range-start,\n.react-datepicker__day--range-end,\n.react-datepicker__day--in-range,\n.react-datepicker__day--keyboard-selected {\n  @apply bg-secondary text-primary font-semibold;\n}\n\n.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--in-range) {\n  @apply bg-secondary text-primary;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
