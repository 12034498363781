import React from 'react'
import {removeItem, showItemEditor} from '../../store/shoppingCartSlice'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import * as types from '../../types'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faImage,
  faRemove,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import ShoppingCartPricingDetails from './ShoppingCartPricingDetails'
import {formatDate, generateUUID} from '../../services/utils'
import {addToastNotification} from '../../store/toastNotificationSlice'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import {
  selectPropertiesById,
  selectUnitGroupsById
} from '../../store/configSlice'

const logger = getLogger('ShoppingCartItem')

export interface ShoppingCartItemProps {
  item: types.ShoppingCartItem
  itemIndex?: number
  showItemIndex?: boolean
  showActions?: boolean
  className?: string
}

const ShoppingCartItem = ({
  item,
  itemIndex = -1,
  showItemIndex = false,
  showActions = false,
  className
}: ShoppingCartItemProps) => {
  const dispatch = useAppDispatch()
  const propertyConfigLookup = useAppSelector(selectPropertiesById)
  const unitGroupConfigLookup = useAppSelector(selectUnitGroupsById)
  const propertyConfig = propertyConfigLookup
    ? propertyConfigLookup[item?.propertyId]
    : null
  const propertyName = propertyConfig?.name
  const propertyLocation = propertyConfig?.location
  const unitGroupConfig = unitGroupConfigLookup
    ? unitGroupConfigLookup[item?.offer?.unitGroup?.id]
    : null
  const roomImage = unitGroupConfig?.images?.[0]

  console.log('unitGroupConfig', {unitGroupConfig, unitGroupConfigLookup})

  return (
    <div
      className={clsx(
        'card card-compact border bg-white shadow w-full',
        className
      )}
    >
      <div className="card-body">
        {/* card header */}
        <div className="flex flex-col gap-1">
          {showItemIndex && (
            <div className="badge bg-cabana text-white text-xs font-semibold">
              room {itemIndex + 1}
            </div>
          )}
          <div className="w-full">
            <h4 className="text-xs text-cabana font-semibold uppercase">
              {propertyLocation}
            </h4>

            <h3 className="font-medium text-md md:text-lg">
              {propertyName && <span>{propertyName} &#8226; </span>}
              <span>{item.offer.unitGroup.name}</span>
            </h3>
            <div
              className={clsx(
                'text-xs md:text-sm text-light-midnight',
                'flex flex-col md:flex-row'
              )}
            >
              <span>{item.offer.ratePlan.name}</span>
              <span className="hidden md:block mx-1">&#8226;</span>
              <span>
                {formatDate(item.offer.arrival)}-
                {formatDate(item.offer.departure)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-2 w-full">
          <div className="w-1/3">
            {/* item image */}
            <div className="w-full h-full">
              {roomImage ? (
                <img
                  src={roomImage}
                  alt={item.offer.unitGroup.name}
                  className="object-cover rounded-xl"
                />
              ) : (
                <div className="h-full flex items-center justify-center bg-gray-200 rounded-xl">
                  <FontAwesomeIcon icon={faImage} className="text-2xl" />
                </div>
              )}
            </div>
          </div>

          <div className="w-2/3 flex flex-col items-end gap-2">
            {/* item price details */}
            <ShoppingCartPricingDetails item={item} className="flex-1" />

            {/* item actions */}
            {showActions && (
              <div className="flex-0 flex flex-row justify-end gap-2">
                <a
                  className={clsx(
                    'flex flex-row gap-1 items-center',
                    'link text-xs md:text-sm font-medium text-cabana no-underline hover:underline'
                  )}
                  onClick={() => {
                    logger.info({
                      message: 'Removing room item from shopping cart...',
                      data: {item}
                    })
                    ReactGA.event({
                      category: 'IBE',
                      action: 'Shopping_Cart_Remove',
                      value: 1
                    })
                    dispatch(removeItem(item.id))
                    dispatch(
                      addToastNotification({
                        id: generateUUID(),
                        type: 'success',
                        message: 'Removed room from shopping cart.'
                      })
                    )
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  <span>remove room</span>
                </a>
                <a
                  className={clsx(
                    'flex flex-row gap-1 items-center',
                    'link text-xs md:text-sm font-medium text-cabana no-underline hover:underline'
                  )}
                  onClick={() => {
                    logger.info({
                      message: 'Editing room item in shopping cart...',
                      data: {item}
                    })
                    ReactGA.event({
                      category: 'IBE',
                      action: 'Shopping_Cart_Edit',
                      value: 1
                    })
                    dispatch(showItemEditor({item, mode: 'Edit'}))
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <span>edit room</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartItem
