import React from 'react'
import {IbeConfig, OfferResult} from '../../types'
import SearchUnitResultItem from './SearchUnitResultItem'
import {getPropertyById, getUnitGroupOffers} from '../../services/utils'
import {getLogger} from '../../services/logging'

const logger = getLogger('SearchUnitResults')

export interface SearchUnitResultsProps {
  config: IbeConfig
  offerResults?: OfferResult[]
  selectedPropertyId: string
  isLoading: boolean
}

/**
 *
 * @param selectedPropertyId
 * @param config
 * @returns
 */
function formatPropertySelectionLabel(
  selectedPropertyId: string,
  config: IbeConfig
): string {
  if (selectedPropertyId === 'ALL') {
    return 'All Properties'
  } else {
    const propertyConfig = getPropertyById(
      config.properties,
      selectedPropertyId
    )
    return propertyConfig?.name || 'Selected Property'
  }
}

const SearchUnitResults = ({
  config,
  offerResults = [],
  selectedPropertyId,
  isLoading
}: SearchUnitResultsProps) => {
  const propertySelectionLabel = formatPropertySelectionLabel(
    selectedPropertyId,
    config
  )
  const unitGroupOffers = getUnitGroupOffers(selectedPropertyId, offerResults)

  logger.debug({
    message: 'Rendering SearchUnitResults...',
    data: {unitGroupOffers}
  })

  if (isLoading) {
    return (
      <div className="flex-1 flex flex-col gap-8 mt-8">
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
      </div>
    )
  } else if (unitGroupOffers?.length) {
    return (
      <>
        <div className="text-sm mt-4">
          Showing <span className="font-bold">{unitGroupOffers.length}</span>{' '}
          room results for {propertySelectionLabel}:
        </div>
        <div className="flex flex-col gap-8 mt-4 mb-8">
          {unitGroupOffers.map((unitGroupOffer, index) => {
            console.log('unitGroupOffer: ', unitGroupOffer)
            return (
              <SearchUnitResultItem
                key={index}
                unitGroupOffer={unitGroupOffer}
                propertyId={unitGroupOffer.propertyId}
              />
            )
          })}
        </div>
      </>
    )
  } else {
    return
  }
}

export default SearchUnitResults
