import React from 'react'
import {IbeConfig, UnitGroup} from '../../types'
import ImageCarousel from '../common/ImageCarousel'
import SearchUnitAttributes from './SearchUnitAttributes'
import {useAppSelector} from '../../store/hooks'
import {
  selectPropertiesById,
  selectUnitGroupsById
} from '../../store/configSlice'
import {getCategorizedUnitGroupFeatures} from '../../services/features'

export interface SearchUnitDetailsModalProps {
  propertyId: string
  unitGroup: UnitGroup
  isOpen: boolean
  onClose: () => void
}

const SearchUnitDetailsModal = ({
  propertyId,
  unitGroup,
  isOpen,
  onClose
}: SearchUnitDetailsModalProps) => {
  const propertyLookup = useAppSelector(selectPropertiesById)
  const propertyConfig = propertyLookup[propertyId]
  const unitGroupConfigsById = useAppSelector(selectUnitGroupsById)
  const unitGroupConfig = unitGroupConfigsById[unitGroup?.id]
  const features = unitGroupConfig?.features || []
  const categorizedFeatures = getCategorizedUnitGroupFeatures(features)

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open p-4"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box w-full p-0">
            <div className="w-full h-72 rounded-t-2xl overflow-hidden">
              <ImageCarousel images={unitGroupConfig?.images} />
            </div>
            <button
              className="btn btn-sm btn-circle btn-white absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
            <div className="modal-body overflow-y-scroll max-h-96 pr-4 p-4">
              {/* unit group title */}
              <h1 className="font-semibold text-xl mb-1">{unitGroup.name}</h1>

              {/* property name/city */}
              <div className="text-sm text-light-midnight text-pretty mb-2">
                {propertyConfig.name} &#x2022; {propertyConfig.location}{' '}
              </div>

              {/* unit group attributes */}
              <SearchUnitAttributes
                unitAttributes={unitGroupConfig?.attributes}
              />

              {/* unit group description */}
              <p className="my-1 text-sm text-light-midnight">
                {unitGroup.description}
              </p>

              <div className="divider my-2"></div>

              {features.length > 0 ? (
                <>
                  {categorizedFeatures.map(({category, features}) => {
                    if (!features.length) {
                      return null
                    }
                    return (
                      <div key={category} className="mb-4">
                        <h3 className="font-semibold mb-1">{category}</h3>
                        <ul className="list-disc px-5 grid grid-cols-2 gap-0.5 text-sm text-light-midnight">
                          {features.map(feature => {
                            return (
                              <li key={`${category}-${feature}`}>{feature}</li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  })}
                </>
              ) : (
                <div className="text-sm text-light-midnight mb-4">
                  No features available
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchUnitDetailsModal
