import React from 'react'
import * as types from '../../types'
import clsx from 'clsx'
import {formatCurrency} from '../../services/utils'
import {
  calcTotalCharges,
  calcTotalPrepaymentAmount,
  calcTotalRoomCharges,
  calcTotalRoomNights,
  calcTotalServiceCharges,
  calcTotalTaxesAndFees,
  getAllTaxesAndFees
} from '../../services/offerUtils'
import {getLogger} from '../../services/logging'

const logger = getLogger('ShoppingCartPricingDetails')

export interface ShoppingCartPricingDetailsProps {
  item: types.ShoppingCartItem
  className?: string
}

const ShoppingCartPricingDetails = ({
  item,
  className
}: ShoppingCartPricingDetailsProps) => {
  const {offer, additionalServices} = item
  const totalRoomCharges = calcTotalRoomCharges(item.offer)
  const totalRoomNights = calcTotalRoomNights(item.offer)
  const totalServiceCharges = calcTotalServiceCharges(additionalServices)
  const totalTaxesAndFees = calcTotalTaxesAndFees(item.offer, [])
  const totalPrepayment = calcTotalPrepaymentAmount(offer, additionalServices)
  const totalCharges = calcTotalCharges(item.offer, item.additionalServices, [])

  logger.debug({
    message: 'Rendering shopping cart pricing details...',
    data: {
      totalCharges,
      totalPrepayment,
      totalRoomCharges,
      totalServiceCharges,
      totalTaxesAndFees,
      item
    }
  })

  return (
    <div className={clsx('w-full flex flex-col items-end gap-0.5', className)}>
      <table className="price-details text-light-midnight">
        <tbody>
          {/* item total price */}
          <tr className="font-bold">
            <td colSpan={2}>Room Totals:</td>
          </tr>
          <tr>
            <td>Room charges ({totalRoomNights} nights)</td>
            <td className="price">{formatCurrency(totalRoomCharges)}</td>
          </tr>
          {additionalServices.length > 0 && (
            <tr>
              <td>Additional Services</td>
              <td className="price">{formatCurrency(totalServiceCharges)}</td>
            </tr>
          )}

          <tr className="border-b">
            <td>Taxes and Fees</td>
            <td className="price">{formatCurrency(totalTaxesAndFees)}</td>
          </tr>

          {totalPrepayment > 0 && (
            <tr>
              <td className="font-bold">Total Prepayment</td>
              <td className="font-bold price">
                {formatCurrency(totalPrepayment)}
              </td>
            </tr>
          )}
          <tr>
            <td className="font-bold">Total</td>
            <td className="font-bold price">{formatCurrency(totalCharges)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ShoppingCartPricingDetails
