import {z} from 'zod'
import {
  IbeConfigSchema,
  MapCoordinatesSchema,
  OfferResultSchema,
  OfferSchema,
  OfferRequestSchema,
  SearchParamsSchema,
  PropertyConfigSchema,
  PropertySchema,
  SearchGuestParamsSchema,
  SearchDateRangeTypeSchema,
  SearchDateRangeParamsSchema,
  SearchUnitGroupTypeSchema,
  SearchSpecialRatesParamsSchema,
  UnitGroupOfferSchema,
  UnitGroupSchema,
  ShoppingCartItemSchema,
  ServiceSchema,
  ItemEditorModeSchema,
  ItemEditSchema,
  FeeSchema,
  TaxDetailSchema,
  CityTaxSchema,
  TaxAndFeeItemSchema,
  ToastNotificationSchema,
  ServiceOfferRequestSchema,
  ServiceOfferResultsSchema,
  ServiceOfferSchema,
  PaymentSessionRequestSchema,
  CreateIbeBookingMetadataSchema,
  BookingGuestSchema,
  BookingReservationSchema
} from './schema'

export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum AppView {
  HOME = 'Home',
  CHOOSE_PROPERTY = 'ChooseProperty',
  CHOOSE_UNIT = 'ChooseUnit',
  CHECKOUT = 'Checkout',
  CONFIRMATION = 'Confirmation'
}

export type PaymentSessionRequest = z.infer<typeof PaymentSessionRequestSchema>

export type SearchGuestParams = z.infer<typeof SearchGuestParamsSchema>

export type SearchDateRangeType = z.infer<typeof SearchDateRangeTypeSchema>

export type SearchDateRangeParams = z.infer<typeof SearchDateRangeParamsSchema>

export type SearchUnitGroupType = z.infer<typeof SearchUnitGroupTypeSchema>

export type SearchSpecialRatesParams = z.infer<
  typeof SearchSpecialRatesParamsSchema
>

export type SearchParams = z.infer<typeof SearchParamsSchema>

export type OfferRequest = z.infer<typeof OfferRequestSchema>

export type OfferResult = z.infer<typeof OfferResultSchema>

export type Offer = z.infer<typeof OfferSchema>

export type Property = z.infer<typeof PropertySchema>

export type PropertyConfig = z.infer<typeof PropertyConfigSchema>

export type IbeConfig = z.infer<typeof IbeConfigSchema>

export type ItemEditorMode = z.infer<typeof ItemEditorModeSchema>

export type ItemEdit = z.infer<typeof ItemEditSchema>

export type MapCoordinates = z.infer<typeof MapCoordinatesSchema>

export type Service = z.infer<typeof ServiceSchema>

export type ShoppingCartItem = z.infer<typeof ShoppingCartItemSchema>

export type UnitGroup = z.infer<typeof UnitGroupSchema>

export type UnitGroupOffer = z.infer<typeof UnitGroupOfferSchema>

export type Fee = z.infer<typeof FeeSchema>

export type TaxDetail = z.infer<typeof TaxDetailSchema>

export type CityTax = z.infer<typeof CityTaxSchema>

export type TaxAndFeeItem = z.infer<typeof TaxAndFeeItemSchema>

export type ToastNotification = z.infer<typeof ToastNotificationSchema>

export type ServiceOfferRequest = z.infer<typeof ServiceOfferRequestSchema>

export type ServiceOffer = z.infer<typeof ServiceOfferSchema>

export type ServiceOfferResults = z.infer<typeof ServiceOfferResultsSchema>

export type BookingGuest = z.infer<typeof BookingGuestSchema>

export type BookingReservation = z.infer<typeof BookingReservationSchema>

export type CreateIbeBookingMetadata = z.infer<
  typeof CreateIbeBookingMetadataSchema
>

export enum PaymentSessionStatus {
  PaymentSessionCreated = 'PaymentSessionCreated',
  PaymentRejected = 'PaymentRejected',
  PaymentProcessing = 'PaymentProcessing',
  PaymentSucceeded = 'PaymentSucceeded',
  PaymentFailed = 'PaymentFailed'
}
