import {LogLevel} from '../types'

declare var process: {
  env: {
    [key: string]: string
  }
}

export interface AppConfig {
  adyenClientId: string
  adyenEnvironment: string
  adyenPaymentsApiUrl: string
  cognitoRegion: string
  cognitoUserPoolId: string
  cognitoWebClientId: string
  environment: string
  googleAnalyticsId: string
  googleMapsApiKey: string
  ibeApiUrl: string
  loggingApiUrl: string
  loggingEnabled: boolean
  loggingLevel: LogLevel
  metricsApiUrl: string
  metricsEnabled: boolean
  metricsNamespace: string
  reduxDevTools: boolean
}

export function getConfig(): AppConfig {
  return {
    adyenClientId: process.env.ADYEN_CLIENT_ID,
    adyenEnvironment: process.env.ADYEN_ENVIRONMENT,
    adyenPaymentsApiUrl: process.env.ADYEN_PAYMENTS_API_URL,
    cognitoRegion: process.env.COGNITO_REGION,
    cognitoUserPoolId: process.env.COGNITO_USER_POOL_ID,
    cognitoWebClientId: process.env.COGNITO_WEB_CLIENT_ID,
    environment: process.env.ENVIRONMENT,
    googleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID,
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
    ibeApiUrl: process.env.IBE_API_URL,
    loggingApiUrl: process.env.LOGGING_API_URL,
    loggingEnabled: process.env.LOGGING_ENABLED === 'true',
    loggingLevel: process.env.LOGGING_LEVEL as LogLevel,
    metricsApiUrl: process.env.METRICS_API_URL,
    metricsEnabled: process.env.METRICS_ENABLED === 'true',
    metricsNamespace: process.env.METRICS_NAMESPACE,
    reduxDevTools: process.env.REDUX_DEV_TOOLS === 'true'
  }
}
