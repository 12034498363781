import React, {useEffect, useState} from 'react'
import CheckoutGuestInfoForm, {GuestInfo} from './CheckoutGuestInfoForm'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectHasMemberRate} from '../../store/shoppingCartSlice'
import MemberAuthenticator, {
  MemberAuthenticatorView
} from '../auth/MemberAuthenticator'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons'
import {
  clearMember,
  selectIsAuthenticated,
  selectMember
} from '../../store/memberSlice'
import {
  selectBooker,
  selectIsValidBooker,
  setBooker
} from '../../store/bookerSlice'
import CheckoutGuestInfoDisplay from './CheckoutGuestInfoDisplay'
import {Auth} from 'aws-amplify'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import clsx from 'clsx'

const logger = getLogger('CheckoutGuestInfo')

enum GuestInfoView {
  Choose = 'Choose',
  Login = 'Login',
  Register = 'Register',
  MemberForm = 'MemberForm',
  GuestForm = 'GuestForm',
  EditForm = 'EditForm',
  Display = 'Display'
}

interface GuestInfoHeaderProps {
  view: GuestInfoView
}

function GuestInfoHeader({view}: GuestInfoHeaderProps) {
  let subtitle = null

  // set subtitle based on view
  switch (view) {
    case GuestInfoView.Login:
      subtitle = 'Login'
      break
    case GuestInfoView.Register:
      subtitle = 'Create a Member Account'
      break
    case GuestInfoView.GuestForm:
      subtitle = 'Check Out as Guest'
      break
    case GuestInfoView.MemberForm:
      subtitle = 'Check Out as Member'
      break
    case GuestInfoView.EditForm:
      subtitle = 'Edit details'
      break
    default:
      subtitle = null
      break
  }

  return (
    <div className="text-lg">
      <span className="font-semibold">Guest Information</span>
      {subtitle && <span className="font-light">: {subtitle}</span>}
    </div>
  )
}

const CheckoutGuestInfo = () => {
  const dispatch = useAppDispatch()
  const [guestInfoView, setGuestInfoView] = useState<GuestInfoView>(
    GuestInfoView.Choose
  )
  const booker = useAppSelector(selectBooker)
  const member = useAppSelector(selectMember)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isValidBooker = useAppSelector(selectIsValidBooker)
  const cartHasMemberRate = useAppSelector(selectHasMemberRate)

  useEffect(() => {
    if (isValidBooker) {
      // booker is valid, show the guest info
      logger.info({message: 'Booker is valid, show guest info'})
      setGuestInfoView(GuestInfoView.Display)
    } else if (!isValidBooker && isAuthenticated) {
      // booker is invalid but user is authenticated, show the prepopulated form
      logger.info({message: 'Booker is invalid but user is authenticated'})
      setGuestInfoView(GuestInfoView.MemberForm)
    } else {
      // default to choose
      logger.info({
        message: 'Choose to login/register member or checkout as guest'
      })
      setGuestInfoView(GuestInfoView.Choose)
    }
  }, [isValidBooker, isAuthenticated])

  return (
    <div className="card card-compact bg-white border shadow">
      <div className="card-body">
        <GuestInfoHeader view={guestInfoView} />

        {member.isAuthenticated && (
          <div className="flex flex-row items-center gap-2">
            <span className="flex-1">
              Hello,{' '}
              <span className="font-semibold">
                {member?.isAuthenticated ? member?.firstName : 'Guest'}
              </span>
              . You're logged in as a member.
            </span>
            <a
              className="link text-cabana text-bold text-sm flex-0 no-underline hover:underline"
              onClick={() => {
                logger.info({message: 'User signed out'})
                ReactGA.event({
                  category: 'IBE',
                  action: 'User_Logout',
                  value: 1
                })

                // sign out and clear member state
                Auth.signOut()
                dispatch(clearMember())
              }}
            >
              sign out
            </a>
          </div>
        )}

        {/* Choose register, login, or checkout as guest */}
        {guestInfoView === GuestInfoView.Choose && (
          <div className="flex flex-col gap-4">
            <button
              className="btn btn-secondary"
              onClick={() => {
                logger.info({message: 'User chose to register as member'})
                ReactGA.event({
                  category: 'IBE',
                  action: 'Checkout_Member_Register',
                  value: 1
                })
                setGuestInfoView(GuestInfoView.Register)
              }}
            >
              Create a Member Account
            </button>
            <button
              className={clsx('btn btn-primary btn-outline', {
                'border-secondary': !cartHasMemberRate,
                'btn-disabled': cartHasMemberRate
              })}
              disabled={cartHasMemberRate}
              onClick={() => {
                logger.info({message: 'User chose to checkout as guest'})
                ReactGA.event({
                  category: 'IBE',
                  action: 'Checkout_Guest',
                  value: 1
                })
                setGuestInfoView(GuestInfoView.GuestForm)
              }}
            >
              Checkout as Guest
            </button>

            <p className="text-center text-sm text-light-midnight">
              Already a member?{' '}
              <a
                className="link text-cabana font-medium no-underline hover:underline"
                onClick={() => {
                  logger.info({message: 'User chose to login as member'})
                  ReactGA.event({
                    category: 'IBE',
                    action: 'Checkout_Member_Login',
                    value: 1
                  })
                  setGuestInfoView(GuestInfoView.Login)
                }}
              >
                Login here.
              </a>
            </p>
          </div>
        )}

        {/* Form: login/registration */}
        {(guestInfoView === GuestInfoView.Login ||
          guestInfoView === GuestInfoView.Register) && (
          <>
            <MemberAuthenticator
              className="mt-2"
              view={
                guestInfoView === GuestInfoView.Register
                  ? MemberAuthenticatorView.SignUp
                  : MemberAuthenticatorView.SignIn
              }
            />
            {!member.isAuthenticated && (
              <div className="flex justify-center w-full mt-2">
                <a
                  className="link link-primary text-sm"
                  onClick={() => {
                    logger.info({
                      message:
                        'User navigated back from member login/registration'
                    })
                    ReactGA.event({
                      category: 'IBE',
                      action: 'Checkout_Back_Choose',
                      value: 1
                    })
                    setGuestInfoView(GuestInfoView.Choose)
                  }}
                >
                  <FontAwesomeIcon icon={faCaretLeft} className="mr-1" />
                  <span>back</span>
                </a>
              </div>
            )}
          </>
        )}

        {/* Form: checkout as guest */}
        {(guestInfoView === GuestInfoView.GuestForm ||
          guestInfoView === GuestInfoView.MemberForm ||
          guestInfoView === GuestInfoView.EditForm) && (
          <CheckoutGuestInfoForm
            className="mt-4"
            isEditMode={guestInfoView === GuestInfoView.EditForm}
            member={member}
            booker={booker}
            onBack={() => {
              logger.info({message: 'User navigated back from guest info form'})
              setGuestInfoView(GuestInfoView.Choose)
            }}
            onCancel={() => {
              logger.info({
                message: 'User cancelled the edit to guest info form'
              })
              setGuestInfoView(GuestInfoView.Display)
            }}
            onSave={(guestInfo: GuestInfo) => {
              logger.info({
                message: 'User saved updated guest info',
                data: {guestInfo}
              })
              dispatch(setBooker(guestInfo))
              setGuestInfoView(GuestInfoView.Display)
            }}
          />
        )}

        {/* Guest info display */}
        {guestInfoView === GuestInfoView.Display && (
          <CheckoutGuestInfoDisplay
            guestInfo={booker}
            onEdit={() => {
              logger.info({message: 'User is editing guest info'})
              setGuestInfoView(GuestInfoView.EditForm)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default CheckoutGuestInfo
