import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface BookerState {
  firstName: string
  lastName: string
  email: string
  phone: string
  comments?: string
  acceptTerms: boolean
}

const initialState: BookerState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  comments: '',
  acceptTerms: false
}

export const bookerSlice = createSlice({
  name: 'booker',
  initialState,
  reducers: {
    setBooker: (state, action: PayloadAction<BookerState>) => {
      state = action.payload
      return state
    },
    clearBooker: state => {
      state = initialState
      return state
    }
  }
})

// selectors

/**
 * Gets the booker
 * @param state
 * @returns
 */
export const selectBooker = (state: any): BookerState => {
  return state.booker
}

/**
 * Checks if the booker is valid
 */
export const selectIsValidBooker = createSelector(
  [selectBooker],
  (booker: BookerState): boolean => {
    return (
      booker?.firstName?.length > 0 &&
      booker?.lastName?.length > 0 &&
      booker?.email?.length > 0 &&
      booker?.phone?.length > 0 &&
      booker.acceptTerms === true
    )
  }
)

/**
 * Gets the booker's email address
 */
export const selectBookerEmail = createSelector(
  [selectBooker],
  (booker: BookerState): string => {
    return booker.email
  }
)

// actions
export const {setBooker} = bookerSlice.actions

// reducer
export default bookerSlice.reducer
