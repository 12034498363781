import React from 'react'
import Hero from '../../assets/hero-1.png'

const SearchHomeView = () => {
  return (
    <div
      className="hero h-[500px] mt-4 rounded-2xl border"
      style={{
        backgroundImage: `url(${Hero})`
      }}
    >
      <div className="hero-overlay bg-opacity-75 rounded-2xl"></div>
      <div className="hero-content text-neutral-content text-center">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold font-serif">
            Welcome to Placemakr
          </h1>
          <p className="mb-5 text-xl">
            Your stay, your way. Kick off your shoes and enjoy the comforts of a
            place that feels just like home.
          </p>
          <p className="mb-5">
            Sign up today for member-only perks. More comfort, more rewards,
            every time you stay.
          </p>
          <button className="btn btn-primary">Become a Member</button>
        </div>
      </div>
    </div>
  )
}

export default SearchHomeView
