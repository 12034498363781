import {
  faBriefcase,
  faBuildingLock,
  faCar,
  faCity,
  faDumbbell,
  faGolfBallTee,
  faPersonChalkboard,
  faPersonSwimming,
  faStarOfLife,
  faUmbrellaBeach
} from '@fortawesome/free-solid-svg-icons'

export interface CategorizedFeature {
  category: string
  features: string[]
}

const categorizedPropertyFeatures = [
  {
    category: 'Leisure and Wellness',
    features: [
      'Community Kitchen',
      'Fire Pit',
      'Game Room',
      'Golf Simulator',
      'Grill/BBQ',
      'Hot Tub',
      'Indoor Pool',
      'Library',
      'Outdoor Dining Area',
      'Outdoor Pool',
      'Rooftop Lounge',
      'Sauna',
      'Spa',
      'Speakeasy'
    ]
  },
  {
    category: 'Business and Productivity',
    features: ['Coworking Space', 'Event Spaces', 'Free WiFi', 'Meeting Rooms']
  },
  {
    category: 'Fitness',
    features: [
      'Fitness Center',
      'Free Weights',
      'Peloton Bikes',
      'Rowing Machines',
      'Weight Machines',
      'Yoga/Meditation Studio'
    ]
  },
  {
    category: 'Dining and Sundries',
    features: [
      'Breakfast Delivery On Demand',
      'High-End Vending Machine',
      'On-Site Restaurant',
      'On-Site Bar/Lounge',
      'On-Site Market',
      'Vending Machine'
    ]
  },
  {
    category: 'Transportation',
    features: [
      'Bicycle Rentals Nearby',
      'Bicycle Storage',
      'EV Chargers',
      'Off-site Parking',
      'On-Site Parking',
      'Scooter Rentals Nearby',
      'Shuttle Service Available',
      'Street Parking Available',
      'Valet Parking'
    ]
  },
  {
    category: 'Guest Services and Convenience',
    features: [
      '24/7 On-Site Staff',
      '24/7 Staff Support',
      'Digital Key Access',
      'Housekeeping On-Demand',
      'Luggage Storage',
      'Self Check-in',
      'Smart Lock Access'
    ]
  },
  {
    category: 'Safety and Security',
    features: ['Security Cameras']
  },
  {
    category: 'Furry Friends',
    features: ['Cats Welcome', 'Dogs Welcome', 'Outdoor Pet Area']
  }
]

/**
 * Gets the icon for the property feature
 * @param feature
 * @returns The font awesome icon for the property feature
 */
export function getPropertyFeatureIcon(feature: string) {
  switch (feature) {
    case 'Pool':
      return faPersonSwimming
    case 'Rooftop Lounge':
      return faUmbrellaBeach
    case 'Golf Simulator':
      return faGolfBallTee
    case 'Coworking Space':
      return faBriefcase
    case 'Fitness Center':
      return faDumbbell
    case 'City Skyline Views':
      return faCity
    case 'On-site Parking':
      return faCar
    case 'Meeting Rooms':
      return faPersonChalkboard
    case 'Self Check-in':
      return faBuildingLock
    default:
      return faStarOfLife
  }
}

export function getCategorizedPropertyFeatures(
  propertyFeatures: string[]
): CategorizedFeature[] {
  return categorizedPropertyFeatures.map(({category, features}) => {
    return {
      category,
      features: features.filter(feature => propertyFeatures.includes(feature))
    }
  })
}

const categorizedUnitGroupFeatures = [
  {
    category: 'Basic Essentials',
    features: [
      'Air Conditioning',
      'Body Lotion',
      'Clothing Steamer',
      'Clothing Storage',
      'Hair Dryer',
      'Hangers',
      'High Speed WiFi',
      'Hot Water',
      'Iron/Ironing Board',
      'Pool Towels',
      'Shampoo, Conditioner, Body Wash',
      'Smart TV',
      'TV Streaming Ready',
      'Toilet Paper and Paper Towels',
      'Towels and Linens',
      'Washer/Dryer'
    ]
  },
  {
    category: 'Sleeping Comforts',
    features: [
      'Bedside USB Ports',
      'Blackout Curtains',
      'Extra Pillow and Blankets',
      'King Bed',
      'Murphy Bed',
      'Noise Machine',
      'Queen Bed',
      'TV in Bedroom'
    ]
  },
  {
    category: 'Kitchen & Dining',
    features: [
      'Basic Baking Supplies',
      'Coffee Maker',
      'Coffee, Tea, Creamer, Sugar',
      'Dining Table',
      'Dish Soap, Sponges, Cleaning Supplies',
      'Dishes, Silverware, Glassware',
      'Dishwasher',
      'Electric Kettle',
      'Ice Machine',
      'Microwave',
      'Nespresso Machine',
      'Oven/Stovetop',
      'Pots and Pans',
      'Refrigerator',
      'Tea Kettle',
      'Toaster'
    ]
  },
  {
    category: 'Outdoor Activities',
    features: [
      'City Skyline Views',
      'Outdoor Seating',
      'Patio/Balcony',
      'Private Entrance'
    ]
  },
  {
    category: 'Workspace & Business',
    features: ['Dedicated Workspace In-Unit']
  },
  {
    category: 'Family Friendly',
    features: ['Noise Machine Available', "Pack 'N Play Available"]
  },
  {
    category: 'Safety',
    features: [
      'Bedroom Door Lock',
      'Carbon Monoxide Alarm',
      'Fire Extinguisher',
      'Lockbox In-Unit',
      'Security Latches',
      'Smoke Alarm'
    ]
  },
  {
    category: 'ADA Accessibility',
    features: [
      'Accessible Hooks, Shelves, Mirrors',
      'Accessible Toilets, Sinks, Dispensers',
      'Adjustable Mirror',
      'Adjustable Shower Head',
      'Bathroom Grab Bars',
      'Doorway Width Min 32"',
      'Shower Seat',
      'Turning Space Min 60"'
    ]
  }
]

export function getCategorizedUnitGroupFeatures(
  unitGroupFeatures: string[]
): CategorizedFeature[] {
  return categorizedUnitGroupFeatures.map(({category, features}) => {
    return {
      category,
      features: features.filter(feature => unitGroupFeatures.includes(feature))
    }
  })
}
