import React, {useEffect, useRef, useState} from 'react'
import NumberStepper from '../common/NumberStepper'
import clsx from 'clsx'
import {SearchGuestParams} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'

export interface SearchRoomsGuestsControlProps {
  searchGuestParams: SearchGuestParams
  onChange: (searchGuestParams: SearchGuestParams) => void
  className?: string
}

function formatLabel(searchGuestParams: SearchGuestParams): string {
  const rooms = searchGuestParams.rooms || 1
  const adults = searchGuestParams.adults || 1

  const roomLabel = rooms === 1 ? '1 Room' : `${rooms} Rooms`
  const adultLabel = adults === 1 ? '1 Adult' : `${adults} Adults`

  return `${roomLabel}, ${adultLabel}`
}

const SearchRoomsGuestsControl = ({
  searchGuestParams,
  onChange,
  className = ''
}: SearchRoomsGuestsControlProps) => {
  const label = formatLabel(searchGuestParams)

  const detailsRef = useRef<HTMLDetailsElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        detailsRef.current &&
        !detailsRef.current.contains(event.target as Node)
      ) {
        detailsRef.current.removeAttribute('open')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <details ref={detailsRef} className={clsx('dropdown', className)}>
      <summary className="flex flex-row items-center cursor-pointer py-3.5 md:py-1">
        <div className="w-full font-medium text-sm">{label}</div>
        <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-2xs" />
      </summary>

      <div className="dropdown-content menu bg-white rounded-box z-[9999] w-64 p-4 mt-2 shadow flex flex-col gap-3">
        <div className="flex flex-row align-center justify-between pb-2 border-b">
          <span className="text-sm">Rooms</span>
          <NumberStepper
            min={1}
            max={6}
            step={1}
            value={searchGuestParams?.rooms}
            onChange={updatedRooms => {
              if (onChange) {
                const {adults} = searchGuestParams
                onChange({
                  ...searchGuestParams,
                  rooms: updatedRooms,
                  adults: updatedRooms > adults ? updatedRooms : adults
                })
              }
            }}
          />
        </div>
        <div className="flex flex-row align-center justify-between pb-2 border-b">
          <span>Adults</span>
          <NumberStepper
            min={Math.max(1, searchGuestParams?.rooms || 1)}
            max={99}
            step={1}
            value={searchGuestParams?.adults}
            onChange={value => {
              onChange({
                ...searchGuestParams,
                adults: value
              })
            }}
          />
        </div>
        <div className="flex flex-row align-center justify-between pb-2 border-b">
          <span>Children</span>
          <NumberStepper
            min={0}
            max={99}
            step={1}
            value={searchGuestParams?.children}
            onChange={value => {
              onChange({
                ...searchGuestParams,
                children: value
              })
            }}
          />
        </div>
        <div className="flex flex-row align-center justify-between pb-2 border-b">
          <span>Travelling with Infants?</span>
          <input
            type="checkbox"
            className="toggle toggle-sm mr-4"
            checked={searchGuestParams?.hasInfants}
            onChange={e => {
              const hasInfants = e.target.checked
              onChange({
                ...searchGuestParams,
                hasInfants
              })
            }}
          />
        </div>
        <div className="flex flex-row align-center justify-between pb-2 border-b">
          <span>Travelling with Pets?</span>
          <input
            type="checkbox"
            className="toggle toggle-sm mr-4"
            checked={searchGuestParams?.hasPets}
            onChange={e => {
              const hasPets = e.target.checked
              onChange({
                ...searchGuestParams,
                hasPets
              })
            }}
          />
        </div>
        <div className="text-xs font-light text-gray-400">
          A maximum of 6 rooms can be booked online. Please contact{' '}
          <a href="mailto:sales@placemakr.com" target="_blank" className="link">
            sales
          </a>{' '}
          for group bookings.
        </div>
      </div>
    </details>
  )
}

export default SearchRoomsGuestsControl
