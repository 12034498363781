import {
  faBriefcase,
  faBuildingLock,
  faCar,
  faCity,
  faDumbbell,
  faGolfBallTee,
  faPersonChalkboard,
  faPersonSwimming,
  faStarOfLife,
  faUmbrellaBeach
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'

interface SearchPropertyFeaturesProps {
  className?: string
  features: string[]
}

function getFeatureIcon(feature: string) {
  switch (feature) {
    case 'Pool':
      return faPersonSwimming
    case 'Rooftop Lounge':
      return faUmbrellaBeach
    case 'Golf Simulator':
      return faGolfBallTee
    case 'Coworking Space':
      return faBriefcase
    case 'Fitness Center':
      return faDumbbell
    case 'City Skyline Views':
      return faCity
    case 'On-site Parking':
      return faCar
    case 'Meeting Rooms':
      return faPersonChalkboard
    case 'Self Check-in':
      return faBuildingLock
    default:
      return faStarOfLife
  }
}

const SearchPropertyFeatures = ({
  features,
  className
}: SearchPropertyFeaturesProps) => {
  return (
    <div className={className}>
      <div className="flex flex-row flex-wrap gap-3 pt-1 text-xs font-medium">
        {features.map(feature => {
          return (
            <div key={feature}>
              <FontAwesomeIcon
                icon={getFeatureIcon(feature)}
                className="text-cabana mr-1"
              />
              <span className="lowercase">{feature}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SearchPropertyFeatures
