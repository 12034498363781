import React from 'react'
import {PropertyConfig} from '../../types'
import ImageCarousel from '../common/ImageCarousel'
import SearchPropertyFeatures from './SearchPropertyFeatures'
import {getCategorizedPropertyFeatures} from '../../services/features'

export interface SearchPropertyDetailsModalProps {
  propertyConfig: PropertyConfig
  isOpen: boolean
  onClose: () => void
}

const SearchPropertyDetailsModal = ({
  propertyConfig,
  isOpen,
  onClose
}: SearchPropertyDetailsModalProps) => {
  const features = propertyConfig?.features || []

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open p-4"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box w-full p-0 pb-6">
            <div className="w-full h-64 rounded-t-2xl overflow-hidden">
              <ImageCarousel images={propertyConfig.propertyImages} />
            </div>
            <button
              className="btn btn-sm btn-circle btn-white absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
            <div className="modal-body overflow-y-scroll max-h-96 pr-4 mt-2 px-8 py-4">
              <h2 className="font-semibold text-xl">{propertyConfig?.name}</h2>
              {propertyConfig?.highlightedFeatures && (
                <SearchPropertyFeatures
                  className="mb-2"
                  features={propertyConfig.highlightedFeatures}
                />
              )}
              <div className="text-sm text-light-midnight">
                {propertyConfig?.description}
              </div>
              <div className="divider my-1"></div>
              <h3 className="font-semibold text-md mb-1">Property Amenities</h3>
              <ul className="list-disc pl-5 grid grid-cols-2 gap-0 mr-1 text-sm text-light-midnight">
                {features.map(feature => (
                  <li key={feature}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchPropertyDetailsModal
