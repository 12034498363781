import React, {useEffect, useRef, useState} from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import {
  CoreOptions,
  PaymentMethodsConfiguration
} from '@adyen/adyen-web/dist/types/core/types'
import {getConfig} from '../../services/config'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {clearPaymentSession} from '../../services/adyenPaymentsApi'
import {getLogger} from '../../services/logging'

const logger = getLogger('CheckoutPaymentForm')
const {adyenClientId, adyenEnvironment} = getConfig()

const renderAdyenCheckout = async (
  paymentContainer: any,
  paymentMethods: any,
  paymentSession: any,
  onPaymentCompleted: Function,
  onPaymentError: Function
) => {
  const payMessage = 'Book Now'
  const paymentMethodsConfig: PaymentMethodsConfiguration = {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      billingAddressRequired: true,
      name: 'Credit or Debit Card'
    }
  }

  const configuration: CoreOptions = {
    paymentMethodsResponse: paymentMethods,
    paymentMethodsConfiguration: paymentMethodsConfig,
    clientKey: adyenClientId,
    environment: adyenEnvironment,
    locale: 'en-US',
    session: {
      id: paymentSession?.id,
      sessionData: paymentSession?.sessionData
    },
    translations: {
      'en-US': {
        confirmPurchase: payMessage,
        confirmPreauthorization: payMessage
      }
    },
    onPaymentCompleted: (result: any) => {
      logger.info({
        message: 'Payment accepted and being processed.',
        data: {result}
      })
      if (onPaymentCompleted) {
        if (result.resultCode === 'Authorised') {
          if (onPaymentCompleted) {
            onPaymentCompleted(result)
          }
        } else {
          // handle immediate payment errors
          if (onPaymentError) {
            onPaymentError(result)
          }
        }
      }
    },
    onError: (error: any) => {
      logger.error({
        message: 'Payment failed',
        data: {error, paymentSession, paymentMethods}
      })
      if (onPaymentError) {
        onPaymentError(error)
      }
    }
  }

  const checkout = await AdyenCheckout(configuration)
  const component = checkout
    .create('dropin', {showPayButton: true})
    .mount(paymentContainer.current)

  // return component cleanup function
  return () => {
    try {
      if (checkout && component) {
        checkout.remove(component)
      }
    } catch (e) {
      console.warn('Failed to cleanup adyen payment component', e)
      logger.warn({
        message: 'Failed to cleanup adyen payment component',
        data: {error: e}
      })
    }
  }
}

interface CheckoutPaymentFormProps {
  paymentMethods: any
  paymentSession: any
  onPaymentCompleted: (result: any) => void
}

const CheckoutPaymentForm = ({
  paymentMethods,
  paymentSession,
  onPaymentCompleted
}: CheckoutPaymentFormProps) => {
  // get reference to container element
  const paymentContainer = useRef(null)
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    if (paymentContainer && paymentSession && typeof window !== 'undefined') {
      const allowedPaymentMethods = {
        paymentMethods: paymentMethods.paymentMethods.filter(
          (pm: any) => pm.name === 'Credit Card'
        )
      }

      const onPaymentError = (result: any) => {
        // set error state
        setHasError(true)
        // scroll to top of page
        window.scrollTo(0, 0)
        // clear the payment session
        // clearPaymentSession()
      }

      // render the adyen payment component
      const checkoutPromise = renderAdyenCheckout(
        paymentContainer,
        allowedPaymentMethods,
        paymentSession,
        onPaymentCompleted,
        onPaymentError
      )

      // handle errors
      checkoutPromise.catch(e => {
        console.error('Error rendering Adyen payment form', e)
      })

      // cleanup function - removes payment component
      return () => {
        // invoke the cleanup function
        // TODO: fix cleanup function
        // checkoutPromise.then(cleanup => cleanup())
      }
    } else {
      return
    }
  }, [paymentContainer, paymentSession])

  return (
    <div className="flex flex-col gap-4">
      {hasError && (
        <div className="alert alert-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <p>
            There was an error processing your payment. Please make sure your
            payment information is correct and try again. If the problem
            persists, please contact customer support.
          </p>
        </div>
      )}
      <div ref={paymentContainer} />
    </div>
  )
}

export default CheckoutPaymentForm
