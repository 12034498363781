import React from 'react'
import CheckoutPaymentForm from './CheckoutPaymentForm'
import {
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery
} from '../../services/adyenPaymentsApi'
import {useAppSelector} from '../../store/hooks'
import {selectCartItemsProperty} from '../../store/shoppingCartSlice'
import {selectBooker} from '../../store/bookerSlice'
import {selectBookingMetadata} from '../../store/selectors'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import Alert from '../common/Alert'

const logger = getLogger('CheckoutPayment')

export interface CheckoutPaymentProps {
  isActive: boolean
  onPaymentProcessing: (paymentSessionId: string) => void
}

const CheckoutPayment = ({
  isActive,
  onPaymentProcessing
}: CheckoutPaymentProps) => {
  // TODO: replace with real propertyId
  // const propertyId = useAppSelector(selectCartItemsProperty)
  const propertyId = 'TEST'

  const booker = useAppSelector(selectBooker)
  const paymentMethodsQuery = useGetPaymentMethodsQuery(propertyId as string, {
    skip: !isActive || !propertyId
  })
  const paymentMethods = paymentMethodsQuery?.data
  const bookingMetadata = useAppSelector(selectBookingMetadata)
  const paymentSessionQuery = useGetPaymentSessionQuery(
    {
      amountInCents: 0,
      isPaymentAccount: true,
      metadata: bookingMetadata,
      propertyId,
      returnUrl: window.location.href,
      shopperEmail: booker.email
    },
    {
      skip: !isActive || !propertyId
    }
  )
  const paymentSession = paymentSessionQuery?.data

  logger.debug({
    message: 'Rendering CheckoutPayment...',
    data: {paymentSession, paymentMethods}
  })

  return (
    <div className="card card-compact bg-white border shadow">
      <div className="card-body">
        <h1 className="text-lg font-semibold">Payment</h1>

        {isActive && paymentMethods && paymentSession ? (
          <div className="mt-4 flex flex-col gap-1">
            <Alert type="info" className="mb-2">
              Credit card details are needed to guarantee your stay. Your card
              will not be charged.
            </Alert>
            <CheckoutPaymentForm
              paymentMethods={paymentMethods}
              paymentSession={paymentSession}
              onPaymentCompleted={(result: any) => {
                const paymentSessionId =
                  paymentSession?.metadata?.paymentSessionId
                logger.info({
                  message: 'Payment completed',
                  data: {paymentSessionId, result}
                })
                ReactGA.event({
                  category: 'IBE',
                  action: 'Checkout_Payment_Submit',
                  value: 1
                })
                onPaymentProcessing(paymentSessionId)
              }}
            />
          </div>
        ) : (
          ''
        )}

        {isActive && (!paymentMethods || !paymentSession) ? (
          <div className="my-8 flex flex-row gap-2 items-center">
            <span className="loading loading-spinner loading-sm"></span>
            <h2 className="text-sm">Creating secure payment session...</h2>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default CheckoutPayment
