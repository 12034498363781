import React from 'react'
import {faBathtub, faBed, faPerson} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export interface SearchUnitAttributesProps {
  unitAttributes: {
    bedrooms: number
    bathrooms: number
    maxGuests: number
  }
  className?: string
}

const SearchUnitAttributes = ({
  unitAttributes,
  className = ''
}: SearchUnitAttributesProps) => {
  if (!unitAttributes) {
    return null
  } else {
    const {bedrooms, bathrooms, maxGuests} = unitAttributes
    return (
      <div className={className}>
        <div className="flex flex-row flex-wrap gap-3 text-xs font-medium">
          <div>
            <FontAwesomeIcon icon={faBed} className="text-cabana mr-1" />
            <span className="lowercase">
              {bedrooms === 1 ? '1 Bedroom' : `${bedrooms} Bedrooms`}
            </span>
          </div>
          <div>
            <FontAwesomeIcon icon={faBathtub} className="text-cabana mr-1" />
            <span className="lowercase">
              {bathrooms === 1 ? '1 Bathroom' : `${bathrooms} Bathrooms`}
            </span>
          </div>
          <div>
            <FontAwesomeIcon icon={faPerson} className="text-cabana mr-1" />
            <span className="lowercase">
              {maxGuests === 1 ? '1 Guest' : `${maxGuests} Guests`}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchUnitAttributes
