import React, {useEffect} from 'react'
import SearchBar from './SearchBar'
import SearchPropertyResults from '../search-property/SearchPropertyResults'
import {AppView, IbeConfig, SearchParams} from '../../types'
import {useParallelSearchOffersQuery} from '../../hooks/useParallelSearchOffersQuery'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectSearch, setSearch} from '../../store/searchSlice'
import {getPropertiesByLocation} from '../../services/utils'
import SearchUnitResults from '../search-unit/SearchUnitResults'
import {setAppView} from '../../store/appViewSlice'
import Breadcrumb from '../common/Breadcrumb'
import {getLogger} from '../../services/logging'
import SearchHomeView from './SearchHomeView'

const logger = getLogger('SearchView')

interface SearchViewProps {
  appView: AppView
  config: IbeConfig
}

const SearchView = ({appView, config}: SearchViewProps) => {
  const dispatch = useAppDispatch()
  const searchParams = useAppSelector(selectSearch)
  const {
    offerResults,
    loading,
    triggerParallelFetch
  } = useParallelSearchOffersQuery()

  useEffect(() => {
    if (searchParams && searchParams.location !== 'ALL') {
      const properties = getPropertiesByLocation(
        config.properties,
        searchParams.location
      )

      const {dateRange, guests, specialRates} = searchParams
      const requests = properties.map(property => {
        return {
          propertyId: property.propertyApaleoId,
          dateRange,
          guests,
          specialRates
        }
      })

      logger.info({
        message: 'Searching for properties...',
        data: {requests}
      })

      triggerParallelFetch(requests)
    }
  }, [searchParams])

  return (
    <section>
      <Breadcrumb appView={appView} className="mb-2" />
      <SearchBar
        config={config}
        searchParams={searchParams}
        showProperty={appView === AppView.CHOOSE_UNIT}
        onSubmit={(searchParams: SearchParams) => {
          dispatch(setSearch(searchParams))

          if (searchParams.propertyId) {
            dispatch(setAppView(AppView.CHOOSE_UNIT))
          } else {
            dispatch(setAppView(AppView.CHOOSE_PROPERTY))
          }
        }}
        className="mb-8"
      />

      {appView === AppView.HOME && <SearchHomeView />}

      {appView === AppView.CHOOSE_PROPERTY && (
        <SearchPropertyResults
          config={config}
          searchParams={searchParams}
          offerResults={offerResults}
          isLoading={loading}
          onSelectProperty={(propertyId: string) => {
            console.log('propertyId: ', propertyId)
            dispatch(setSearch({...searchParams, propertyId}))
            dispatch(setAppView(AppView.CHOOSE_UNIT))
          }}
        />
      )}

      {appView === AppView.CHOOSE_UNIT && (
        <SearchUnitResults
          config={config}
          offerResults={offerResults}
          selectedPropertyId={searchParams.propertyId || ''}
          isLoading={loading}
        />
      )}
    </section>
  )
}

export default SearchView
