import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ItemEdit, ItemEditorMode, ShoppingCartItem} from '../types'
import {
  calcTotalCharges,
  calcTotalPrepaymentAmount,
  isMemberRate
} from '../services/offerUtils'

export interface ShoppingCartState {
  items: ShoppingCartItem[]
  isCartOpen: boolean
  isItemEditorOpen: boolean
  itemEditorMode: ItemEditorMode
  itemToEdit: ShoppingCartItem | null
}

const initialState: ShoppingCartState = {
  items: [],
  isCartOpen: false,
  isItemEditorOpen: false,
  itemEditorMode: 'Add',
  itemToEdit: null
}

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    showCart: (state: ShoppingCartState) => {
      return {
        ...state,
        isItemEditorOpen: false,
        isCartOpen: true
      }
    },
    hideCart: (state: ShoppingCartState) => {
      return {
        ...state,
        isCartOpen: false
      }
    },
    addItem: (
      state: ShoppingCartState,
      action: PayloadAction<ShoppingCartItem>
    ) => {
      return {
        ...state,
        items: [...state.items, action.payload]
      }
    },
    updateItem: (
      state: ShoppingCartState,
      action: PayloadAction<ShoppingCartItem>
    ) => {
      const index = state.items.findIndex(item => item.id === action.payload.id)
      if (index >= 0) {
        state.items[index] = action.payload
      }
    },
    removeItem: (state: ShoppingCartState, action: PayloadAction<string>) => {
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload)
      }
    },
    showItemEditor: (
      state: ShoppingCartState,
      action: PayloadAction<ItemEdit>
    ) => {
      return {
        ...state,
        isCartOpen: false,
        isItemEditorOpen: true,
        itemEditorMode: action.payload?.mode,
        itemToEdit: action.payload.item
      }
    },
    updateItemEditor: (
      state: ShoppingCartState,
      action: PayloadAction<ShoppingCartItem>
    ) => {
      return {
        ...state,
        itemToEdit: action.payload
      }
    },
    hideItemEditor: (state: ShoppingCartState) => {
      return {
        ...state,
        isItemEditorOpen: false,
        itemToEdit: null
      }
    },
    clearCart: (state: ShoppingCartState) => {
      return {
        ...state,
        items: []
      }
    }
  }
})

// selectors
export const selectCartItems = (state: any): ShoppingCartItem[] => {
  return state.shoppingCart?.items || []
}

export const selectCartItemCount = createSelector(
  selectCartItems,
  (items: ShoppingCartItem[]): number => {
    return items?.length || 0
  }
)

export const selectCartHasItems = createSelector(
  selectCartItemCount,
  (count: number): boolean => {
    return count > 0
  }
)

export const selectIsCartOpen = (state: any): boolean => {
  return state.shoppingCart?.isCartOpen || false
}

export const selectIsItemEditorOpen = (state: any): boolean => {
  return state.shoppingCart?.isItemEditorOpen || false
}

export const selectItemToEdit = (state: any): ShoppingCartItem | null => {
  return state.shoppingCart?.itemToEdit
}

export const selectItemEditorMode = (state: any): ItemEditorMode => {
  return state.shoppingCart?.itemEditorMode
}

/**
 * Checks if there are items in the cart that are member rates
 */
export const selectHasMemberRate = createSelector([selectCartItems], items => {
  return items.some(item => {
    return isMemberRate(item.offer)
  })
})

/**
 * Gets the property IDs of the cart items
 */
export const selectPropertyIds = createSelector(
  selectCartItems,
  (items: ShoppingCartItem[]): string[] => {
    const properties: string[] = []
    for (const item of items) {
      if (!properties.includes(item.propertyId)) {
        properties.push(item.propertyId)
      }
    }
    return properties
  }
)

/**
 * Gets the property ID of the cart items
 */
export const selectCartItemsProperty = createSelector(
  selectCartItems,
  items => {
    return items?.length > 0 ? items[0].propertyId : null
  }
)

/**
 * Checks if there are items in the cart that are from multiple properties
 */
export const selectIsMultiProperty = createSelector(
  selectPropertyIds,
  (propertyIds: string[]): boolean => {
    return propertyIds.length > 1
  }
)

/**
 * Gets shopping cart items grouped by property
 */
export const selectCartItemsByProperty = createSelector(
  selectCartItems,
  (items: ShoppingCartItem[]): {[key: string]: ShoppingCartItem[]} => {
    return items.reduce((acc, item) => {
      if (!acc[item.propertyId]) {
        acc[item.propertyId] = []
      }
      acc[item.propertyId].push(item)
      return acc
    }, {} as {[key: string]: ShoppingCartItem[]})
  }
)

export interface CartTotals {
  total: number
  totalPrepayment: number
}

export const selectCartTotals = createSelector(
  selectCartItems,
  (items: ShoppingCartItem[]): CartTotals => {
    return items.reduce(
      (acc, item) => {
        const itemPrepayment = calcTotalPrepaymentAmount(
          item.offer,
          item.additionalServices
        )
        const itemCharges = calcTotalCharges(
          item.offer,
          item.additionalServices,
          []
        )

        acc.total += itemCharges
        acc.totalPrepayment += itemPrepayment
        return acc
      },
      {total: 0, totalPrepayment: 0}
    )
  }
)

// actions
export const {
  showCart,
  hideCart,
  clearCart,
  addItem,
  updateItem,
  removeItem,
  showItemEditor,
  updateItemEditor,
  hideItemEditor
} = shoppingCartSlice.actions

// reducer
export default shoppingCartSlice.reducer
