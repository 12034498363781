import React from 'react'
import {formatCurrency} from '../../services/utils'
import {ServiceOffer} from '../../types'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'

const logger = getLogger('ShoppingCartServiceItem')

export interface ShoppingCartServiceItemProps {
  serviceOffer: ServiceOffer
  isSelected: boolean
  onSelectionChange: (isSelected: boolean) => void
  className?: string
}

const ServicePricingDetail = ({serviceOffer}: {serviceOffer: ServiceOffer}) => {
  const {
    service: {pricingUnit},
    dates
  } = serviceOffer
  const nights = dates.length
  const pricePerUnit = dates[0].amount.grossAmount
  const guests = serviceOffer.count

  if (pricingUnit === 'Room') {
    return (
      <div className="lowercase text-xs text-light-midnight leading-4 md:text-right">
        {formatCurrency(pricePerUnit)} x{' '}
        {nights > 1 ? `${nights} nights` : '1 night'}
      </div>
    )
  }

  if (pricingUnit === 'Person') {
    return (
      <div className="lowercase text-xs text-light-midnight leading-4 md:text-right">
        {formatCurrency(pricePerUnit)} x{' '}
        {guests > 1 ? `${guests} guests` : '1 guest'} x{' '}
        {nights > 1 ? `${nights} nights` : '1 night'}
      </div>
    )
  }

  return null
}

const ShoppingCartServiceItem = ({
  serviceOffer,
  isSelected,
  onSelectionChange,
  className = ''
}: ShoppingCartServiceItemProps) => {
  const {
    service: {name, description},
    totalAmount
  } = serviceOffer

  logger.debug({
    message: 'Rendering ShoppingCartServiceItem...',
    data: {serviceOffer, isSelected}
  })

  return (
    <div
      className={clsx(
        'card card-compact border bg-white shadow w-full',
        className
      )}
    >
      <div className="flex flex-row gap-4 px-4 py-3 items-center">
        <div className="w-12">
          <input
            type="checkbox"
            className="toggle"
            checked={isSelected}
            onChange={() => {
              onSelectionChange(!isSelected)
            }}
          />
        </div>
        <div className="flex flex-col md:flex-row md:items-center gap-1 w-full">
          <div className="flex-1 flex flex-col gap-0">
            <div className="font-medium text-md leading-5">{name}</div>
            <div className="text-xs text-light-midnight">{description}</div>
          </div>
          <div className="flex flex-col gap-0 md:items-end">
            <span className="font-medium text-md leading-5">
              {formatCurrency(totalAmount.grossAmount)}
            </span>
            <ServicePricingDetail serviceOffer={serviceOffer} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartServiceItem
