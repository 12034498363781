import React from 'react'
import {Offer, ServiceOffer} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import {getLineItems, isMemberRate} from '../../services/offerUtils'
import {useAppSelector} from '../../store/hooks'
import {selectGuestsPerRoom, selectSearch} from '../../store/searchSlice'
import {useSearchServiceOffersQuery} from '../../services/ibeApi'

export interface SearchUnitOfferDetailsModalProps {
  offer: Offer
  isOpen: boolean
  onClose: () => void
}

const SearchUnitOfferDetailsModal = ({
  offer,
  isOpen,
  onClose
}: SearchUnitOfferDetailsModalProps) => {
  const ratePlanId = offer?.ratePlan?.id || ''
  const search = useAppSelector(selectSearch)
  const guestsPerRoom = useAppSelector(selectGuestsPerRoom)
  const serviceOffersQuery = useSearchServiceOffersQuery(
    {
      ratePlanId,
      adults: guestsPerRoom,
      dateRange: search.dateRange
    },
    {skip: !ratePlanId}
  )
  const serviceOffers = serviceOffersQuery.data?.services || []
  const serviceOffersLoading = serviceOffersQuery.isLoading

  const lineItems = getLineItems(offer)

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open p-4"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box">
            <div className="modal-header">
              <button
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                onClick={onClose}
              >
                ✕
              </button>
              <h2 className="font-semibold text-xl">{offer.unitGroup.name}</h2>
              <h3 className="text-lg">{offer.ratePlan.name}</h3>
            </div>
            <div className="divider my-0"></div>

            {/* TODO: use overflow-y-scroll max-h-96 ? */}
            <div className="modal-body mt-4 overflow-y-scroll">
              <div className="flex flex-col gap-4">
                {/* <pre className="bg-yellow-300">
                  <code>{JSON.stringify(serviceOffersQuery.data, null, 2)}</code>
                </pre> */}

                {/* Member Rate Details */}
                {isMemberRate(offer) && (
                  <div className="rounded-lg bg-gray-100 p-3">
                    <h3 className="font-semibold border-b mb-2">
                      Member Rate Details
                    </h3>
                    <p className="text-sm">
                      Book direct as a Placemakr Member and save{' '}
                      <span className="bg-yellow-300 mb-2">18.5%</span> off our
                      retail rate if you pay now. No changes or refunds. If
                      cancelled, the amount paid will serve as the cancellation.
                    </p>
                    <div className="flex flex-row items-center text-sm gap-2 mt-2">
                      <FontAwesomeIcon
                        icon={faStar}
                        className="text-cabana mr-1"
                      />
                      <p className="italic">
                        It's always free and easy to join as a Placemakr Member.
                        Complete sign up at checkout.
                      </p>
                    </div>
                  </div>
                )}

                {/* Cancellation Policy */}
                <div className="rounded-lg bg-gray-100 p-3">
                  <h3 className="font-semibold border-b mb-2">
                    Cancellation Policy
                  </h3>
                  <p className="text-sm">
                    <span className="font-medium">
                      {offer?.cancellationFee?.name}:
                    </span>{' '}
                    {offer?.cancellationFee?.description}
                  </p>
                </div>

                {/* No Show Policy */}
                <div className="rounded-lg bg-gray-100 p-3">
                  <h3 className="font-semibold border-b mb-2">
                    No Show Policy
                  </h3>
                  <p className="text-sm">
                    <span className="font-medium">
                      {offer?.noShowFee?.name}:
                    </span>{' '}
                    {offer?.noShowFee?.description}
                  </p>
                </div>

                {/* Pricing */}
                <div className="rounded-lg bg-gray-100 p-3">
                  <h3 className="font-semibold border-b mb-2">
                    Pricing Breakdown
                  </h3>
                  <div className="text-sm">
                    {/* Pricing - Line Items */}
                    {lineItems.map(lineItem => {
                      return (
                        <div
                          key={`${lineItem.date}-${lineItem.description}`}
                          className="flex flex-row justify-between w-full"
                        >
                          <div>{lineItem.description}</div>
                          <div className="flex flex-row items-center">
                            <span className="mr-0.5">${lineItem.amount}</span>
                            <span className="uppercase text-xs">
                              {lineItem.currency}
                            </span>
                          </div>
                        </div>
                      )
                    })}

                    <div className="divider my-0"></div>

                    {/* Pricing Totals */}
                    <div className="flex flex-row justify-between w-full">
                      <div className="font-semibold">Total:</div>
                      <div className="flex flex-row items-center font-semibold">
                        <span className=" mr-0.5">
                          ${offer.totalGrossAmount.amount}
                        </span>
                        <span className="uppercase text-xs">
                          {offer.totalGrossAmount.currency}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Service Details */}
                <div className="rounded-lg bg-gray-100 p-3">
                  <h3 className="font-semibold border-b mb-2">
                    Optional Services
                  </h3>
                  {/* <div className="bg-yellow-300">TODO: implement services</div> */}
                  {serviceOffersLoading && <div>Loading...</div>}
                  {serviceOffers && (
                    <>
                      {serviceOffers.map((serviceOffer: ServiceOffer) => {
                        return (
                          <div
                            key={serviceOffer.service.id}
                            className="flex flex-row justify-between w-full text-sm"
                          >
                            <div>{serviceOffer.service.name}</div>
                            <div className="flex flex-row items-center">
                              <span className="mr-0.5">
                                ${serviceOffer.totalAmount.grossAmount}
                              </span>
                              <span className="uppercase text-xs">
                                {serviceOffer.totalAmount.currency}
                              </span>
                              <span className="ml-1 lowercase text-xs">
                                /{serviceOffer.service.pricingUnit}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>

                {/* Placemakr Policies */}
                <div className="rounded-lg bg-gray-100 p-3">
                  <h3 className="font-semibold border-b mb-2">
                    Placemakr Policies
                  </h3>

                  <p className="mb-2 text-sm">
                    <span className="font-medium">Reservation Guarantee:</span>{' '}
                    All reservations require a valid credit card at the time of
                    booking. Your reservation is held until midnight on the day
                    of arrival, unless prior arrangements are made.
                  </p>

                  <p className="text-sm">
                    <span className="font-medium">ID Verification:</span> All
                    reservations require that the guest present a valid
                    government-issued ID prior to check-in.
                  </p>
                </div>

                {/* Contact Info */}
                <div className="italic text-xs text-center text-light-midnight mt-1">
                  Have questions? Contact us at info@placemakr.com
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchUnitOfferDetailsModal
